import { Col, Row, Skeleton } from 'antd';
import CommentsPanel from 'components/Comments/CommentsPanel';
import PhoneCallPlayer from 'components/PhoneCallPlayer';
import SCard from 'components/Standard/SCard';
import ConversationPart from 'components/TextCommunicationContent/components/ConversationPart';
import { CHECKLIST_MANAGER_STATES } from 'core/utils/constants';
import { get, isEmpty, pickBy } from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import CustomFields from '../CustomFields';

const Call = ({
  affixOffsetTop,
  affixTarget = () => window,
  clientInteraction,
  review,
  comments,
  allowCommenting = true,
  loadingComments,
  addingComment,
  checklistManagerState,
  onAddCommentButton,
  onCommentSave,
  onCancelComment,
  onDeleteComment,
  onUpdateComment,
  shouldLoad,
  transcription,
  fromDrawer
}) => {
  const canComment =
    allowCommenting &&
    (isEmpty(review) || checklistManagerState === CHECKLIST_MANAGER_STATES.EDITING);

  const onMessageCommentSave = useCallback(
    message => comment => {
      onAddCommentButton();
      onCommentSave({
        ...comment,
        ratingFlag: comment.metadata.ratingFlag,
        metadata: { ...comment.metadata, messageId: message.id }
      });
    },
    [onAddCommentButton, onCommentSave]
  );

  const getMessageComments = useCallback(
    message =>
      Object.values(comments).filter(comment => message.id === comment?.metadata?.messageId),
    [comments]
  );

  if (isEmpty(clientInteraction)) {
    return <Skeleton active />;
  }

  const underPlayerComments = pickBy(comments, comment =>
    isEmpty(get(comment, 'metadata.questionId'))
  );

  const refs = transcription?.parts.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const handleMessageBoxClick = id =>
    refs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });

  return (
    <>
      <Row
        gutter={[16, 8]}
        style={{
          position: 'static',
          top: fromDrawer ? affixOffsetTop : 14,
          zIndex: 2
        }}
      >
        <Col span={24}>
          <PhoneCallPlayer
            call={clientInteraction}
            review={review}
            comments={Object.values(comments)}
            shouldLoad={shouldLoad}
            messages={transcription?.parts}
            onMessageClick={handleMessageBoxClick}
          />
        </Col>
      </Row>
      <CustomFields clientInteraction={clientInteraction} />
      <Row>
        <Col span={24}>
          {transcription ? (
            <SCard
              bodyPadding="16px 6px"
              bodyStyle={{
                maxHeight: !fromDrawer ? 'calc(100vh - 304px)' : 'calc(100vh - 240px)',
                overflow: 'hidden auto'
              }}
            >
              <Row gutter={[16, 16]}>
                {transcription?.parts
                  .sort((a, b) => a?.startTime - b?.startTime)
                  .map(message => (
                    <ConversationPart
                      id={message.id}
                      postedAt={moment(clientInteraction.startedAt).add(
                        message.startTime,
                        'seconds'
                      )}
                      seconds={message.startTime}
                      operatorId={
                        message.role === 'operator' ? clientInteraction?.operatorId : undefined
                      }
                      body={message.text}
                      ref={refs[message.id]}
                      textCommunicationStartedAt={clientInteraction?.startedAt}
                      onAddCommentButton={onAddCommentButton}
                      onCommentSave={onMessageCommentSave(message)}
                      onCancelComment={onCancelComment}
                      onDeleteComment={onDeleteComment}
                      onUpdateComment={onUpdateComment}
                      allowCommenting={allowCommenting}
                      comments={getMessageComments(message)}
                    />
                  ))}
              </Row>
            </SCard>
          ) : (
            <CommentsPanel
              comments={underPlayerComments}
              loading={!isEmpty(review) && loadingComments}
              addingComment={addingComment}
              allowCommenting={canComment}
              showCommentsList
              onAddCommentButton={onAddCommentButton}
              onCommentSave={onCommentSave}
              onCancelComment={onCancelComment}
              onDeleteComment={onDeleteComment}
              onUpdateComment={onUpdateComment}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Call);
